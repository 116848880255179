import { useState } from "react"
import styled from "styled-components"

import { TextButton } from "src/ui/Button/TextButton"
import { MDetails, MDetailsProps } from "src/ui/ExpandableSection/MDetails"
import { sessionStorageFactory } from "src/utils/storageUtil"

/**
 * In addition to its own props, this component supports all props of the base
 * MDetails component, except open and onChange. We're omitting those, in order
 * to create an 'uncontrolled' component, of sorts.
 */
export type TExpandableSection = {
  initialOpen?: boolean
  storeState?: boolean
  buttonLabel?: string
  onButtonClick?: () => void
  alwaysShowButton?: boolean
} & Omit<MDetailsProps, "open" | "onChange">

export function ExpandableSection({
  initialOpen,
  storeState,
  id,
  buttonLabel,
  onButtonClick,
  alwaysShowButton,
  title,
  ...mDetailProps
}: TExpandableSection) {
  if (storeState && !id) {
    throw Error("If you want to store card state, an id is required.")
  }

  const storedOpenState = sessionStorageFactory<boolean>({
    key: `minut.expander.${id}`,
  })

  const [open, setOpen] = useState<boolean>(() => {
    if (initialOpen != null) return initialOpen
    return storedOpenState.get() ?? false
  })

  function handleOpen() {
    if (open) {
      setOpen(false)
      storedOpenState.clear()
    } else {
      setOpen(true)
      storeState && storedOpenState.set(true)
    }
  }

  return (
    <MDetails
      id={id}
      title={
        <ExpandableTitleWrapper>
          {title}
          {buttonLabel && (open || alwaysShowButton) && (
            <TextButton onClick={onButtonClick}>{buttonLabel}</TextButton>
          )}
        </ExpandableTitleWrapper>
      }
      {...mDetailProps}
      open={open}
      onChange={handleOpen}
    />
  )
}

const ExpandableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
